button {
background-color: Transparent;
background-repeat:no-repeat;
border: none;
cursor:pointer;
overflow: hidden;
outline:none;
}

.loading {
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-family: sans-serif;
color: #464646;
}

.rocksGlass {
max-height: 80vh;
animation: shake 4s infinite ease;
}


.rocksGlass:active, .rocksGlassLoading {
    max-height: 80vh;
    animation: shake 0.2s infinite ease;
}

.iceLeft {
/* transform-origin set in px for firefox compatibility */
transform-origin: 36px 66px;
animation: clink .8s infinite ease;
}

.iceRight {
/* transform-origin set in px for firefox compatibility */
transform-origin: 60px 78px;
animation: clink .8s .2s infinite ease;
}

.btn {
position: absolute;
top: 0;
border-top-left-radius: 0;
border-top-right-radius: 0;
font-family: monospace;
}

@keyframes shake {
0% {
    transform: rotate(5deg);
}
50% {
    transform: rotate(-5deg);
}
100% {
    transform: rotate(5deg);
}
}

@keyframes clink {
0% {
    transform: rotate(-10deg);
}
50% {
    transform: rotate(20deg);
}
100% {
    transform: rotate(-10deg);
}
}

