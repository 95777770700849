.listColour {
    color: #f9C80E;
    margin-bottom: 30px;
    font-family: OutrunFuture;
}

.listColour:hover {
    color: #FF6C11;
}

.header {
    height: 10vh;
    background-image: linear-gradient(#261447, #2E2157);
}

main {
    display: flex;
    justify-content: center;
    background-image: linear-gradient(#2E2157, #FD3777);
    padding-top: 50px;
    height: calc(85vh - 50px);
}

footer {
    height: 5vh;
    text-align: center;
    background-image: linear-gradient(#FD3777, #F706CF);
    font-family: OutrunFuture;
    font-size: 10px;
}

.navList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-left:10vw;
    padding-right: 10vw;
    font-size: 15px;
    margin: 0;
}

.mobileNavlist
{
    display: none;
    margin: 0;
}
.menuIcon {
    display: none
}

@media (max-width: 690px) {
    .menuIcon {
        display: block;
        margin:auto;
        width: 45px;
        height: 45px;
        color: #FF6C11;
    }
    .navList {
        display: flex;
        width: 100%;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 0;
        transition: all 0.5s ease;
        flex-direction: column;
        list-style-type: none;
        grid-gap: 0px;
        margin: 0;
    }
    .navListActive {
        display: flex;
        width: 100%;
        position: absolute;
        flex-direction: column;
        list-style-type: none;
        grid-gap: 0px;
        margin: 0;
        background-image: linear-gradient(#FD3777, #F706CF);
        top: 80px;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        align-content: center;
        padding: 0;
    }
  }