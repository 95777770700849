.search {
    width: 500px;
    margin-bottom: 15px;

}

@media (max-width: 690px) {
    .search {
        width: 85vw;
    }
}