.App {
  text-align: center;
  height: 100%;
  overflow: hidden;
}


a, a:visited {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

table {
  width: 100%;
}

input {
  border: none;
}