.shake {
    font-family: OutrunFuture;
    font-size: 35px;
    color: #FF3864;
}

.different {
    font-family: OutrunFuture;
    font-size: 25px;
    color: #FF3864;
}