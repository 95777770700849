.ingredientsButton {
    font-family: OutrunFuture;
    color: #F9C80E;
    font-size: 30px;
    margin-bottom: 30px;
}

.tabStyle {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;

}
.tabTitle {
    font-family: OutrunFuture;
    color: #F9C80E;
}

.tabTitle:hover {
    color: red;
}

.tableStyle {
    font-family: OutrunFuture;
    text-align: center;
    color: #2DE2E6;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-image: linear-gradient(#2E2157, #FD3777);
    border-radius: 4px;
    border: 2px solid #f9C80E;
    width: 25vw;
    padding: 30px;
}

.closeButton {
    /* margin-top: 20px; */
    height: 20px;
    color: #F9C80E;
    align-items: flex-end;
}

.instructions {
    font-family: OutrunFutureBold;
    color: #023788;
    letter-spacing: 2px;
}

@media (max-width: 690px) {
    .modal {
        width: 75vw;
        height: 50vh;
    }
    .ingredientsButton {
        font-size: 20px;
    }
}